<template>
  <section :class="$style.container">
    <img :class="$style.bg" src="./images/bg.jpg" alt="" />
    <img :class="$style.bgTab" src="./images/bg-tab.jpg" alt="" />
    <img :class="$style.bgMob" src="./images/bg-mob.jpg" alt="" />
  </section>
</template>

<script>
export default {
  name: 'SectionIntro'
}
</script>

<style lang="scss" module>
.container {
  width: 100%;

  img {
    width: 100%;
  }
}

.bg {
  @include down('lg') {
    display: none;
  }
}

.bgTab {
  @include up('lg') {
    display: none;
  }

  @include down('md') {
    display: none;
  }
}

.bgMob {
  @include up('md') {
    display: none;
  }
}

//
//.content {
//  max-width: 148em;
//  margin: auto;
//}
//
//.title {
//  text-align: center;
//  margin-bottom: 8.5em;
//
//  @include down(md) {
//    margin-bottom: 6em;
//  }
//
//  .t {
//    font-family: var(--primary-font);
//    font-style: italic;
//    font-weight: 900;
//    font-size: 9.6em;
//    line-height: 120%;
//    text-transform: uppercase;
//    color: #ffffff;
//    display: block;
//
//    @include down(md) {
//      font-size: 3.2em;
//      white-space: nowrap;
//    }
//
//    &:last-child {
//      font-size: 6.4em;
//      line-height: 100%;
//      margin-top: 0.15625em;
//      color: #b78fff;
//
//      @include down(md) {
//        font-size: 3.2em;
//      }
//    }
//  }
//}
//
//.list {
//  width: 89.6em;
//  margin: 0 auto;
//  position: relative;
//  padding-right: 19.6em;
//  z-index: 2;
//
//  &:before {
//    z-index: -1;
//    content: '';
//    display: block;
//    position: absolute;
//    left: 50%;
//    top: 50%;
//    transform-origin: center center;
//    transform: translate(-50%, -50%) scale(0.7);
//    border-radius: 147em;
//    background: radial-gradient(
//      50% 50% at 50% 50%,
//      #c7abff 0%,
//      rgba(199, 171, 255, 0) 100%
//    );
//    filter: blur(13.5em);
//    width: 147.9rem;
//    height: 66.5rem;
//
//    @include down(md) {
//      transform: translate(-50%, -50%) scale(0.5);
//      filter: blur(8em);
//    }
//  }
//
//  @include down(md) {
//    width: 100%;
//    padding: 0;
//  }
//}
//
//.clearItem,
//.listItem {
//  .t {
//    font-family: var(--primary-font);
//    font-style: italic;
//    font-weight: 900;
//    font-size: 3.2em;
//    line-height: 120%;
//    text-transform: uppercase;
//    color: #fff;
//
//    @include down(md) {
//      font-size: 2em;
//    }
//  }
//}
//
//.listItem {
//  padding: 3.3em 4em;
//  background: #092c40;
//  border-radius: 18em;
//  background: rgba(0, 0, 39, 0.5);
//
//  @include down(md) {
//    padding: 1.8em 1.6em;
//  }
//
//  & + & {
//    margin-top: 2.4em;
//  }
//}
//
//.clearItem {
//  margin-top: 2.4em;
//  padding: 1.5em 4em;
//
//  @include down(md) {
//    padding: 0 1.6em;
//  }
//
//  .t {
//    color: #b78fff;
//  }
//
//  .t:last-child {
//    margin-left: 2em;
//    margin-top: 0.13em;
//
//    @include down(md) {
//      margin-left: 1.1em;
//      margin-top: 0.18em;
//    }
//  }
//}
//
//.listImage {
//  right: -3.8%;
//  top: -21.4%;
//  width: 44.5em;
//  position: absolute;
//
//  @include down(md) {
//    right: -32%;
//    top: -15.8%;
//    width: 24.4em;
//  }
//}
</style>
