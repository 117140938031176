<template>
  <div :class="$style.card">
    <p :class="$style.count" v-if="0">
      <span :class="$style.t">x{{ card.count }}</span>
    </p>

    <img :src="card.img" alt="" loading="lazy" />

    <div :class="$style.content">
      <p :class="$style.name">
        <span :class="$style.t">{{ card.name }}</span>
      </p>

      <ui-coin
        :value="price"
        :code="card.code"
        :size="matchMedia.up('sm') ? 'medium' : 'small'"
      />
    </div>
  </div>
</template>

<script>
import number from '~/utils/number'
import UiCoin from '~/components/ui/coin/index.vue'

export default {
  name: 'ItemsCard',
  components: { UiCoin },
  props: {
    card: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    price() {
      return number(this.card.price)
    }
  },
  setup() {
    const { $matchMedia: matchMedia } = useNuxtApp()
    return { matchMedia }
  }
}
</script>

<style lang="scss" module>
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #30384c 0%, rgba(29, 33, 43, 0) 100%);

  border-radius: 1.2em;
  @include down(md) {
    border-radius: 0.6em;
  }

  .count {
    position: absolute;
    top: 2em;
    right: 2em;
    z-index: 2;
    @include down(md) {
      top: 1em;
      right: 1em;
    }

    .t {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.65em;
      line-height: 120%;
      letter-spacing: -0.02em;
      opacity: 0.5;
      @include down(md) {
        font-size: 0.8em;
      }
    }
  }

  img {
    height: 68%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    object-fit: contain;
    @include down(md) {
      height: 60%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    padding: 0 2em 2em;
    margin-top: auto;
    z-index: 2;
    @include down(md) {
      gap: 1.3em;
      padding: 1.2em 0.8em;
    }
    --primary-font-style: normal;
  }

  .name {
    .t {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6em;
      line-height: 150%;
      @include down(md) {
        font-size: 1.2em;
        line-height: 120%;
      }
    }
  }

  .price {
    display: flex;
    align-items: center;
    gap: 0.6em;

    .icon {
      width: 3em;
      height: 3em;
      @include down(md) {
        width: 2.6em;
        height: 2.6em;
      }

      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .t {
      font-style: normal;
      font-weight: 700;
      font-size: 3.7em;
      line-height: 100%;
      @include down(md) {
        font-size: 1.9em;
      }
    }
  }
}
</style>
