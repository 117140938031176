import type { Store } from 'lvl-io-sdk'
import { createItemsStore } from '~/utils/pinia/light-items-store'

type Resource = Pick<Store.ProductExpanded['resource'], 'name' | 'image_url'>

type Product = Pick<
  Store.ProductExpanded,
  'id' | 'price' | 'amount' | 'coin'
> & {
  resource: Resource
}

export const useShopLandingProductsStore = createItemsStore(
  'shop-landing-products',
  {
    getItemsRequests: async () => {
      const { $sdk } = useNuxtApp()
      const {
        products: { idKey, getItems, getMoreItems }
      } = await $sdk.module('store')

      return {
        idKey,

        getItems: (
          ...[requestQuery, pathOptions, requestOptions]: Parameters<
            typeof getItems<Product>
          >
        ) =>
          getItems<Product>(
            {
              fields: [
                'id',
                'amount',
                'price',
                'resource',
                'resource.image_url',
                'resource.name'
              ],
              'filter[category]': 'landing',
              expand: ['resource', 'coin'],
              sort: 'price',
              ...(requestQuery ?? {})
            },
            pathOptions,
            {
              cache: {
                ttl: 1800000 // 30m
              },
              ...(requestOptions ?? {})
            }
          ),

        getMoreItems: getMoreItems<Product>
      }
    }
  }
)
