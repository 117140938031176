<template>
  <svg
    width="157"
    height="60"
    viewBox="0 0 157 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_0_754)">
      <path
        d="M152.626 0.0178223C150.743 0.0178223 149.081 1.22827 148.496 3.01282L138.837 32.6997H147.964L156.752 5.69206C157.662 2.89321 155.573 0.0178223 152.621 0.0178223H152.626Z"
        fill="white"
      />
      <path
        d="M89.4616 0.677979H60.8521L58.2454 8.68698H78.2496L52.491 26.035C50.5171 27.3651 50.1338 30.1065 51.6624 31.9246C52.9799 33.4938 55.2748 33.8144 56.9712 32.6709L82.9643 15.1602L77.2579 32.6996H86.3851L94.5304 7.66312C95.6518 4.21361 93.0787 0.677979 89.4472 0.677979H89.4616Z"
        fill="#00C7B1"
      />
      <path
        d="M19.2494 0.677979L8.82812 32.6996H17.9557L25.7704 8.69178H44.4233L31.7213 17.2462C29.8815 18.4853 29.2682 20.9923 30.4899 22.839C31.7405 24.7289 34.256 25.1978 36.0863 23.9634L51.9076 13.3038C57.5613 9.49554 54.8591 0.682767 48.0408 0.687551L19.2494 0.677979Z"
        fill="white"
      />
      <path
        d="M134.136 0.687551L105.344 0.677979L94.9233 32.6996H104.051L111.866 8.69178H130.518L114.012 19.8106L124.223 31.9676C125.54 33.5369 127.835 33.8574 129.531 32.7139C131.505 31.3839 131.889 28.6424 130.36 26.8244L125.871 21.4803L138.003 13.3086C143.656 9.50033 140.954 0.687551 134.136 0.692334V0.687551Z"
        fill="white"
      />
      <path
        d="M69.7135 56.9566L75.0704 40.4985C75.358 39.6087 76.1918 39.0059 77.1309 39.0059H143.175C144.646 39.0059 145.69 40.4412 145.236 41.8382L139.879 58.2963C139.591 59.186 138.757 59.7891 137.818 59.7891H71.774C70.3031 59.7891 69.2587 58.3535 69.7135 56.9566Z"
        fill="#00C7B1"
      />
      <path
        d="M123.137 46.4743L114.182 46.4648H114.177L114.163 46.5174L113.253 49.321H109.927L111.81 43.5368L111.825 43.4889L111.868 43.355L111.883 43.3071H115.203L123.142 43.3215C123.822 43.3215 124.431 43.7569 124.646 44.4123C124.977 45.4314 124.215 46.4743 123.137 46.4743Z"
        fill="black"
      />
      <path
        d="M121.145 49.2394L119.363 54.7129V54.7273L119.306 54.9087H115.985L108.046 54.8943C107.365 54.8943 106.757 54.4593 106.546 53.8036C106.211 52.7845 106.977 51.7417 108.05 51.7417L117.006 51.751V51.7319L117.82 49.2346H121.145V49.2394Z"
        fill="black"
      />
      <path
        d="M104.916 43.3411H94.5041L93.5552 46.2547H100.834L91.4617 52.5701C90.743 53.0531 90.6041 54.0531 91.1596 54.7134C91.6387 55.283 92.4725 55.4026 93.0905 54.9861L102.549 48.6134L100.474 54.9959H103.794L106.761 45.8864C107.168 44.6329 106.233 43.3459 104.911 43.3459L104.916 43.3411Z"
        fill="black"
      />
      <path
        d="M79.3632 43.3411L75.5732 54.9959H78.8939L81.735 46.2595H88.5246L83.9008 49.3742C83.2302 49.8238 83.0097 50.7377 83.4506 51.4075C83.9054 52.0964 84.8208 52.264 85.4868 51.8191L91.2459 47.9388C93.3017 46.5514 92.3192 43.3459 89.8375 43.3459H79.3586L79.3632 43.3411Z"
        fill="black"
      />
      <path
        d="M138.034 46.469L129.079 46.4594H129.074L129.06 46.512L128.149 49.3157H124.824L126.707 43.5314L126.721 43.4835L126.765 43.3496L126.779 43.3018H130.099L138.039 43.3161C138.719 43.3161 139.327 43.7514 139.543 44.4069C139.873 45.426 139.112 46.469 138.034 46.469Z"
        fill="black"
      />
      <path
        d="M136.037 49.2353L134.254 54.7088V54.7227L134.197 54.9047H130.876L122.937 54.8903C122.257 54.8903 121.648 54.4552 121.437 53.7995C121.102 52.7805 121.868 51.7377 122.942 51.7377L131.897 51.747V51.7279L132.711 49.2305H136.037V49.2353Z"
        fill="black"
      />
      <path
        d="M22.012 51.4883H14.6265L13.9536 53.5661H19.1176L12.4681 58.0671C11.9585 58.4125 11.8596 59.1238 12.2541 59.5954C12.5943 60.0027 13.1867 60.0857 13.6246 59.7887L20.3348 55.246L18.8616 59.7965H21.2179L23.3206 53.3006C23.61 52.4058 22.9458 51.4883 22.0083 51.4883H22.012Z"
        fill="white"
      />
      <path
        d="M33.7721 52.5866C33.7721 53.1516 33.3109 53.6093 32.742 53.6093H30.2921L28.2634 59.8727H25.9054L27.934 53.6093H25.5308C24.9619 53.6093 24.5006 53.1516 24.5006 52.5866C24.5006 52.0222 24.9619 51.5645 25.5308 51.5645H32.742C33.3109 51.5645 33.7721 52.0222 33.7721 52.5866Z"
        fill="white"
      />
      <path
        d="M44.0619 52.5866C44.0619 53.1516 43.6007 53.6093 43.0317 53.6093H40.5819L38.5532 59.8727H36.1952L38.2238 53.6093H35.8206C35.2516 53.6093 34.7904 53.1516 34.7904 52.5866C34.7904 52.0222 35.2516 51.5645 35.8206 51.5645H43.0317C43.6007 51.5645 44.0619 52.0222 44.0619 52.5866Z"
        fill="white"
      />
      <path
        d="M45.4032 52.1809C45.5516 51.7186 45.9793 51.4053 46.4612 51.4053C47.2171 51.4053 47.7531 52.149 47.5192 52.8748L45.9826 57.6459L50.1908 57.648C50.954 57.648 51.4989 58.3851 51.2673 59.1036C51.1195 59.5619 50.6895 59.8722 50.2036 59.8717L43.6104 59.8691L43.6106 59.8686H42.9266L45.4032 52.1809Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.6949 51.4883L10.0912 51.4905L10.0923 51.4894C11.844 51.4894 12.5375 53.7733 11.0854 54.7599L8.61141 56.0259L9.64685 57.2836L10.3413 58.1047C10.3627 58.1305 10.3789 58.1527 10.3905 58.1717C10.5078 58.3357 10.5763 58.5331 10.5763 58.7454C10.5763 59.3171 10.0803 59.7805 9.46858 59.7805L2.36624 59.781L2.36389 59.7882H0.0185547L2.6949 51.4883ZM3.03369 57.7109L7.31565 57.7104L5.54936 55.5887L9.16217 53.5645H4.3706L3.03369 57.7109Z"
        fill="white"
      />
      <path
        d="M56.5071 51.565C56.5205 51.5645 56.5335 51.5645 56.5464 51.5645H60.7401C61.3121 51.5645 61.7762 52.0294 61.7762 52.6031C61.7762 53.1773 61.3121 53.6423 60.7401 53.6423H57.4757V53.6428H56.6661L56.3151 54.7366H59.6513C60.2455 54.7366 60.7267 55.1933 60.7267 55.7562C60.7267 56.3196 60.2455 56.7758 59.6513 56.7758H55.6615L55.3228 57.8335H58.6187C59.2398 57.8335 59.7437 58.2897 59.7437 58.8531C59.7437 59.416 59.2398 59.8727 58.6187 59.8727H52.3351L54.9986 51.5645L56.5071 51.565Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.9978 39.022L44.3882 39.0242L44.3893 39.0231C46.1396 39.0231 46.8325 41.3053 45.3815 42.2907L42.9096 43.5559L43.9442 44.8129L44.6382 45.6331C44.6595 45.6587 44.6757 45.6808 44.6873 45.6999C44.8045 45.864 44.8729 46.0613 44.8729 46.2734C44.8729 46.8445 44.3773 47.3075 43.7661 47.3075L36.6694 47.3079L36.6671 47.3152H34.3236L36.9978 39.022ZM37.3363 45.2396L41.6149 45.2393L39.85 43.1191L43.46 41.0967H38.6722L37.3363 45.2396Z"
        fill="white"
      />
      <path
        d="M19.0831 39.0054C19.0961 39.0048 19.1091 39.0046 19.1222 39.0046H23.3122C23.8841 39.0046 24.3477 39.4694 24.3477 40.0428C24.3477 40.6162 23.8841 41.081 23.3122 41.081H20.0507V41.0815H19.2415L18.8912 42.1744H22.2246C22.818 42.1744 23.2991 42.6305 23.2991 43.1932C23.2991 43.7559 22.818 44.2121 22.2246 44.2121H18.238L17.8994 45.2687H21.1926C21.8136 45.2687 22.317 45.7248 22.317 46.2875C22.317 46.8502 21.8136 47.3063 21.1926 47.3063H14.9139L17.5754 39.0048L19.0831 39.0054Z"
        fill="white"
      />
      <path
        d="M13.1614 41.0832L11.1547 47.306H13.4998L15.4861 41.2931C15.858 40.1671 15.0183 39.0074 13.8308 39.0071L13.8301 39.0092L6.78327 39.0071L4.10718 47.306H6.45228L8.45879 41.0832H13.1614Z"
        fill="white"
      />
      <path
        d="M25.9972 39.0071L33.3931 39.0093L33.3942 39.0082C35.1457 39.0082 35.8392 41.2921 34.3871 42.2783L29.5602 45.5692L28.2241 43.9641L32.4641 41.0833H27.6728L25.6662 47.3064H23.321L25.9972 39.0071Z"
        fill="white"
      />
      <path
        d="M62.1825 36.7992C62.1009 36.9104 62.0519 37.0488 62.0519 37.1975C62.0519 37.5699 62.3549 37.8725 62.7277 37.8725H67.543C67.7671 37.8725 67.9648 37.7639 68.0882 37.5971C68.1708 37.4846 68.2188 37.3462 68.2188 37.1975C68.2188 36.825 67.9168 36.5225 67.543 36.5225H62.7277C62.5036 36.5225 62.3059 36.6311 62.1825 36.7992Z"
        fill="white"
      />
      <path
        d="M60.3683 39.6065C60.516 39.1436 60.9408 38.8293 61.42 38.8293C62.1711 38.8293 62.7034 39.5742 62.4716 40.3009L61.2036 44.2683L62.5609 43.1083L67.2245 39.088C67.2787 39.0401 67.338 39.0013 67.4015 38.969C67.5105 38.9082 67.6297 38.8655 67.7536 38.8449C67.8135 38.8345 67.8745 38.8293 67.9364 38.8293C68.6876 38.8293 69.2199 39.5742 68.9881 40.3009L66.749 47.3058H64.4242L65.783 43.0501L60.8071 47.3058H57.9077L60.3683 39.6065Z"
        fill="white"
      />
      <path
        d="M57.8252 38.8374C57.3466 38.8374 56.9212 39.1515 56.7741 39.6143L54.3135 47.3145H56.6383L58.8763 40.3092C59.1092 39.5826 58.5764 38.8374 57.8252 38.8374Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.8152 47.3142L52.5338 47.3116C54.2861 47.3129 54.9799 45.0293 53.5271 44.0426L49.9839 41.6193L50.1549 41.0878L50.8258 39.0149H48.4765L45.8152 47.3142ZM51.604 45.2375L49.3195 43.6818L48.8184 45.2375H51.604Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_754">
        <rect width="157" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconLogo'
}
</script>

<style lang="scss" module></style>
