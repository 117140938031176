<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9165 3.77792L11.39 6.60603C5.09948 8.69251 1.69143 15.4834 3.77792 21.774L6.60602 30.3004C8.69251 36.5909 15.4834 39.999 21.7739 37.9125L30.3004 35.0844C36.5909 32.9979 39.999 26.207 37.9125 19.9165L35.0844 11.39C32.9979 5.09949 26.207 1.69143 19.9165 3.77792Z"
      fill="#6A34B5"
    />
    <path
      d="M28.0092 14.4313L16.7958 14.4346L15.7757 17.5762H23.6172L13.5201 24.386C12.7467 24.9077 12.596 25.9841 13.1955 26.6979C13.7122 27.314 14.6115 27.4398 15.2772 26.991L25.4671 20.1183L23.2297 27.0026H26.8085L30.0014 17.1738C30.442 15.8208 29.4317 14.4313 28.0092 14.4313V14.4313Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPoints'
}
</script>
