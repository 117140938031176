<template>
  <svg
    width="34"
    height="26"
    viewBox="0 0 34 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.7767 0H7.78704L5.78004 6.15187H21.1815L1.37338 19.5464C-0.153678 20.5499 -0.45909 22.6878 0.718928 24.0839C1.72242 25.3056 3.51127 25.5237 4.82018 24.6511L24.8029 11.1694L20.3962 24.6511H27.4207L33.7034 5.36653C34.5324 2.74871 32.569 0 29.7767 0Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconArrow'
}
</script>

<style module lang="scss"></style>
