<template>
  <div
    :class="[
      $style.timerWrap,
      $style[color],
      $style[size],
      { [$style.finished]: isFinished }
    ]"
  >
    <span :class="$style.timer">
      <ui-time
        :class="$style.t"
        format="future-time"
        :value="Date.now() + 3600000"
        :timeout="1000"
        clock
      />
    </span>
  </div>
</template>

<script>
import UiTime from '~/components/ui/time'

export default {
  name: 'CommonTimer',
  components: { UiTime },
  props: {
    color: {
      type: String,
      default: 'green'
    },
    size: {
      type: String,
      default: 'large'
    }
  },
  data() {
    return {
      isFinished: false
    }
  },
  methods: {
    onFinish() {
      this.isFinished = true
    }
  }
}
</script>

<style lang="scss" module>
.timerWrap {
  transform: skew(-15deg);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2em;

  &.finished {
    display: none;
  }

  &.purple {
    background: var(--root-color-accent);
  }

  &.green {
    background: var(--root-color-primary);
    color: var(--root-color-invert);
  }

  &.small {
    height: 1.55em;
    width: 5.4em;
    padding: 0 0.5em;

    .t {
      font-size: 1em;
    }
  }

  &.large {
    height: 2.7em;
    width: 10.5em;
    padding: 0 1em;

    @include down(md) {
      width: 9.5em;
    }

    .t {
      font-size: 1.8em;
    }
  }
}

.timer {
  transform: skew(15deg);
  .t {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.02em;
  }
}
</style>
