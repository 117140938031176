<template>
  <header :class="$style.header">
    <div :class="$style.logo">
      <icon-logo />
    </div>

    <common-button
      size="small"
      :arrow="false"
      :amplitude="[
        'button_click',
        {
          button_name: 'login',
          button_text: 'Вход'
        }
      ]"
    >
      <span>Вход</span>
    </common-button>
  </header>
</template>

<script>
import CommonButton from './button'
import IconLogo from '../icon/logo'

export default {
  name: 'CommonHeader',
  components: {
    IconLogo,
    CommonButton
  }
}
</script>

<style module lang="scss">
.header {
  padding: 2em var(--root-layout-offset);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  @include down(md) {
    padding: 1.6em var(--root-layout-offset);
  }
}

.logo {
  position: relative;
  svg {
    display: block;
    height: auto;
    width: 18.4em;
    @include down(md) {
      width: 10.4em;
    }
  }
}
</style>
